import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const SessionsSelect = ({ selectedSessionIds, onChange }) => {
	const classes = useStyles();
	const [sessions, setSessions] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/whatsapp");
				setSessions(data);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div style={{ marginTop: 6 }}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>{i18n.t("apiWhatsappModal.form.select")}</InputLabel>
				<Select
					value={selectedSessionIds}
					onChange={handleChange}
					label={i18n.t("apiWhatsappModal.form.select")}
				>
					<MenuItem value={''}>&nbsp;</MenuItem>
					{sessions.map(session => (
						<MenuItem key={session.id} value={session.id}>
							{session.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default SessionsSelect;
