import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
      Button,
      TableBody,
      TableRow,
      TableCell,
      IconButton,
      Table,
      TableHead,
      Paper,
      Tooltip,
      Typography,
      CircularProgress,
} from "@material-ui/core";

import {
      Edit,
      CheckCircle,
      SignalCellularConnectedNoInternet2Bar,
      SignalCellularConnectedNoInternet0Bar,
      SignalCellular4Bar,
      CropFree,
      DeleteOutline,
} from "@material-ui/icons";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ApiWhatsAppModal from "../../components/ApiWhatsAppModal";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles(theme => ({
      mainPaper: {
            flex: 1,
            padding: theme.spacing(1),
            overflowY: "scroll",
            ...theme.scrollbarStyles,
      },
      customTableCell: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
      },
      tooltip: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
            maxWidth: 450,
      },
      tooltipPopper: {
            textAlign: "center",
      },
      buttonProgress: {
            color: green[500],
      },
}));

const Connections = () => {
      const classes = useStyles();
      const [apiWhatsAppModalOpen, setApiWhatsAppModalOpen] = useState(false);
      const [loading, setLoading] = useState(false);
      const [dataApi, setDataApi] = useState([]);

      const handleCloseWhatsAppModal = () => {
            setApiWhatsAppModalOpen(false)
      }
      const handleOpenWhatsAppModal = () => {
            setApiWhatsAppModalOpen(true)
      }

      useEffect(() => {
            (async () => {
                  try {
                        const { data } = await api.get("/whatsapp/apiclients");
                        setDataApi(data);
                  } catch (err) {
                        toastError(err);
                  }
            })();
      }, []);

      console.log(dataApi);
      return (
            <MainContainer>
                  <ApiWhatsAppModal
                        open={apiWhatsAppModalOpen}
                        onClose={handleCloseWhatsAppModal}
                        apiId={dataApi?.id}
                  />
                  <MainHeader>
                        <Title>Apis de Conexion</Title>
                        <MainHeaderButtonsWrapper>
                              <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenWhatsAppModal}
                              >
                                    {i18n.t("connections.buttons.add")}
                              </Button>
                        </MainHeaderButtonsWrapper>
                  </MainHeader>
                  <Paper className={classes.mainPaper} variant="outlined">
                        <Table size="small">
                              <TableHead>
                                    <TableRow>
                                          <TableCell align="center">
                                                <div style={{ width: '100px' }}>
                                                      Nombre del Api
                                                </div>
                                          </TableCell>
                                          <TableCell align="center">
                                                <div style={{ width: '100px' }}>
                                                      Nombre de la conexion (Whatsapp)
                                                </div>
                                          </TableCell>
                                          <TableCell align="center">
                                                <div style={{ width: '100px' }}>
                                                      Fecha de creacion
                                                </div>
                                          </TableCell>
                                          <TableCell align="center">
                                                <div style={{ width: '80px' }}>
                                                      Estado
                                                </div>
                                          </TableCell>
                                          <TableCell align="center">
                                                Token
                                          </TableCell>
                                          <TableCell align="center">
                                                Acciones
                                          </TableCell>
                                    </TableRow>
                              </TableHead>
                              <TableBody>
                                    {loading ? (
                                          <TableRowSkeleton />
                                    ) : (
                                          <>
                                                {dataApi?.length > 0 &&
                                                      dataApi.map(data => (
                                                            <TableRow key={data.id}>
                                                                  <TableCell align="center">
                                                                        <div style={{ width: '100px' }}>
                                                                              {data.name}
                                                                        </div>
                                                                  </TableCell>
                                                                  <TableCell align="center">
                                                                        <div style={{ width: '100px' }}>
                                                                              {data.Whatsapp.name}
                                                                        </div>
                                                                  </TableCell>

                                                                  <TableCell align="center">
                                                                        <div style={{ width: '100px' }}>
                                                                              {format(parseISO(data.createdAt), "dd/MM/yy HH:mm")}
                                                                        </div>
                                                                  </TableCell>
                                                                  <TableCell align="center">
                                                                        <div style={{ width: '80px' }}>
                                                                              {data.status && (
                                                                                    "Habilitado"
                                                                              )}
                                                                        </div>
                                                                  </TableCell>
                                                                  <TableCell align="center">
                                                                        <div style={{ maxWidth: '800px', textAlign: "justify", overflow: "hidden" }}>
                                                                              {data.token}
                                                                        </div>
                                                                  </TableCell>
                                                                  <TableCell align="center">
                                                                        <IconButton
                                                                              size="small"
                                                                              onClick={() => { }}
                                                                        >
                                                                              <Edit />
                                                                        </IconButton>

                                                                        <IconButton
                                                                              size="small"
                                                                              onClick={e => {

                                                                              }}
                                                                        >
                                                                              <DeleteOutline />
                                                                        </IconButton>
                                                                  </TableCell>
                                                            </TableRow>
                                                      ))}
                                          </>
                                    )}
                              </TableBody>
                        </Table>
                  </Paper>
            </MainContainer>
      );
};

export default Connections;
